<template>
  <div class="bindPhone_box">
    <div id="captcha"></div>
    <div class="bindPhone_tips">
      <p class="tips">{{this.$t("toast.findAccountTxt2")}}{{communicationName}}</p>
      <p class="userPhone">{{accountInfo.phone}}</p>
    </div>
    <ul class="ft_bindPhone_box">
      <li class="bindPhone_item verifiy_item">
        <input type="text"
               class="verify_input"
               :placeholder="$t('toast.verifycodePlaceholder')"
               v-model="verify_code">
        <button :disabled="showIndex"
                class="fr v_btn verify_btn"
                :class="{ disabled: showIndex }"
                @click="clickVerifyCode">
          {{ btnValue }}
        </button>
      </li>
      <li class=" bindPhone_submit"
          @click="getVeifyCode">
        {{$t('toast.comfirmBtn2')}}
      </li>
    </ul>
  </div>
</template>

<script>
import initNECaptchaWithFallback from '@/assets/js/yidun-captcha'
import { sendVerifyCode, forget_password_verify_code } from '@/api/login'
import { setSessionStorage, getSessionStorage } from '@/utils/stage'
export default {
  data() {
    return {
      btnValue: this.$t('toast.verifycodeBtn'),
      verify_code: '',
      showIndex: false,
      accountInfo: {},
      NECaptcha: '',
      validate: '',
      ydErr: false,
      communicationName: this.$t('toast.comunicaton1'),
      configData: '',
    }
  },
  watch: {
    verify_code: function (val) {
      this.verify_code = val.replace(/\D/g, '')
    },
  },
  mounted() {
    this.NECaptchaInit()
  },
  created() {
    this.init()
  },
  methods: {
    NECaptchaInit() {
      const captchaId = this.$store.getters.captcha_id
      var that = this
      var neWidth = 360 + 'px'
      // initNECaptcha为全局函数，可直接调用
      initNECaptchaWithFallback(
        {
          // config对象，参数配置
          captchaId: captchaId, // 从易盾申请的验证码id
          element: '#captcha', // 容器元素/容易元素选择器
          mode: 'bind', // 验证码模式
          width: neWidth, // 验证按钮宽度，推荐260px-400px
          defaultFallback: false,
          onVerify: function (err, data) {
            if (data) {
              that.sendVerifyCode(data.validate)
              that.NECaptchaInit()
            }
          },
        },
        function onload(instance) {
          // 初始化成功后得到验证实例instance，可以调用实例的方法。
          that.NECaptcha = instance
          that.ydErr = true

          console.log('that.NECaptcha', that.NECaptcha)
        },
        function onerror(err) {
          that.ydErr = false
          // 初始化失败后触发该函数，err对象描述当前错误信息
          console.log(err)
        }
      )
    },
    init() {
      this.configData = JSON.parse(getSessionStorage('message'))
      this.accountInfo = JSON.parse(getSessionStorage('accountInfo'))
      if (this.accountInfo.phone.indexOf('@') > -1) {
        this.communicationName = this.$t('toast.comunicaton2')
      }
    },
    getCode() {
      this.showIndex = true
      let show = true
      let count = ''
      let timer = null
      const TIME_COUNT = 60
      if (!timer) {
        count = TIME_COUNT
        show = false
        timer = setInterval(() => {
          if (count > 0 && count <= TIME_COUNT) {
            count--
          } else {
            show = true
            clearInterval(timer)
            timer = null
          }
          if (count === 0) {
            this.btnValue = this.$t('toast.verifycodeBtn2')
            this.showIndex = false
            return
          } else {
            this.btnValue = count + 's'
          }
        }, 1000)
      }
    },
    clickVerifyCode() {
      if (this.ydErr) {
        this.NECaptcha && this.NECaptcha.verify()
      } else {
        this.sendVerifyCode()
      }
    },
    sendVerifyCode(valid) {
      let validData = ''
      if (typeof valid === 'string') {
        validData = valid
      }
      const data = {
        terminal: this.accountInfo.phone_token,
        scene_id: 4,
        validate: validData,
      }
      const msg = Object.assign(
        JSON.parse(JSON.stringify(this.configData)),
        data
      )
      sendVerifyCode(msg).then((res) => {
        if (res.errcode === 503) {
          if (this.ydErr) {
            this.NECaptcha && this.NECaptcha.verify()
          } else {
            this.sendVerifyCode()
          }
        } else if (res.errcode === 0) {
          this.$toast(res.errmsg)
          this.getCode()
        }
      })
    },
    getVeifyCode() {
      if (this.verify_code === '') {
        this.$toast(this.$t('toast.verifycodeTips'))
        return
      }
      const data = {
        phone_token: this.accountInfo.phone_token,
        verify_code: this.verify_code,
      }
      const msg = Object.assign(
        JSON.parse(JSON.stringify(this.configData)),
        data
      )
      forget_password_verify_code(msg).then((res) => {
        setSessionStorage('accountveify', res.data.csrf_token)
        this.$router.push({ path: '/changePassword' })
      })
    },
  },
}
</script>
<style lang="scss" scope>
@media (min-width: 1600px) {
  .bindPhone_box {
    width: 700px;
    min-width: 500px;
    background: #fff;
    margin: 0 auto;
    padding: 60px;
    padding-bottom: 200px;
    box-sizing: border-box;
    .bindPhone_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 20px;
        font-family: 'SimHei';
        font-weight: 400;
        line-height: 33px;
        color: #575757;
      }
      .userPhone {
        font-size: 22px;
        margin-top: 24px;
        margin-bottom: 50px;
      }
    }
    .ft_bindPhone_box {
      .bindPhone_item {
        width: 100%;
        height: 60px;
        border: 1px solid #cfcfcf;
        opacity: 1;
        border-radius: 6px;
        margin-top: 15px;
        padding: 10px 34px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1px solid #000;
        }
      }
      .bindPhone_submit {
        margin-top: 50px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6px;
        letter-spacing: 0px;
      }
      .verifiy_item {
        padding-right: 200px;
        position: relative;
        .verify_input {
          border-right: 1px solid #cfcfcf;
        }
        .verify_btn {
          width: 200px;
          outline: none;
          height: 60px;
          line-height: 60px;
          font-size: 26px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .bindPhone_box {
    width: 700 * 0.85px;
    min-width: 500 * 0.85px;
    background: #fff;
    margin: 0 auto;
    padding: 60 * 0.85px;
    padding-bottom: 200 * 0.85px;
    box-sizing: border-box;
    .bindPhone_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 20 * 0.85px;
        font-family: 'SimHei';
        font-weight: 400;
        line-height: 33 * 0.85px;
        color: #575757;
      }
      .userPhone {
        font-size: 22 * 0.85px;
        margin-top: 24 * 0.85px;
        margin-bottom: 50 * 0.85px;
      }
    }
    .ft_bindPhone_box {
      .bindPhone_item {
        width: 100%;
        height: 60 * 0.85px;
        border: 1 * 0.85px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.85px;
        margin-top: 15 * 0.85px;
        padding: 10 * 0.85px 34 * 0.85px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.85px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.85px solid #000;
        }
      }
      .bindPhone_submit {
        margin-top: 50 * 0.85px;
        height: 60 * 0.85px;
        line-height: 60 * 0.85px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.85px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.85px;
        letter-spacing: 0 * 0.85px;
      }
      .verifiy_item {
        padding-right: 200 * 0.85px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.85px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.85px;
          outline: none;
          height: 60 * 0.85px;
          line-height: 60 * 0.85px;
          font-size: 26 * 0.85px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
@media (min-width: 500px) and (max-width:1199px) {
  .bindPhone_box {
    width: 700 * 0.7px;
    min-width: 500 * 0.7px;
    background: #fff;
    margin: 0 auto;
    padding: 60 * 0.7px;
    padding-bottom: 200 * 0.7px;
    box-sizing: border-box;
    .bindPhone_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 20 * 0.7px;
        font-family: 'SimHei';
        font-weight: 400;
        line-height: 33 * 0.7px;
        color: #575757;
      }
      .userPhone {
        font-size: 22 * 0.7px;
        margin-top: 24 * 0.7px;
        margin-bottom: 50 * 0.7px;
      }
    }
    .ft_bindPhone_box {
      .bindPhone_item {
        width: 100%;
        height: 60 * 0.7px;
        border: 1 * 0.7px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.7px;
        margin-top: 15 * 0.7px;
        padding: 10 * 0.7px 34 * 0.7px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.7px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.7px solid #000;
        }
      }
      .bindPhone_submit {
        margin-top: 50 * 0.7px;
        height: 60 * 0.7px;
        line-height: 60 * 0.7px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.7px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.7px;
        letter-spacing: 0 * 0.7px;
      }
      .verifiy_item {
        padding-right: 200 * 0.7px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.7px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.7px;
          outline: none;
          height: 60 * 0.7px;
          line-height: 60 * 0.7px;
          font-size: 26 * 0.7px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
@media screen and (max-width:499px) {
  .bindPhone_box {
    width: 380px;
    min-width: 500 * 0.6px;
    border-radius: 6px;
    background: #fff;
    margin: 0 auto;
    padding: 60 * 0.6px;
    padding-bottom: 200 * 0.6px;
    box-sizing: border-box;
    .bindPhone_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 20 * 0.6px;
        font-family: 'SimHei';
        font-weight: 400;
        line-height: 33 * 0.6px;
        color: #575757;
      }
      .userPhone {
        font-size: 22 * 0.6px;
        margin-top: 24 * 0.6px;
        margin-bottom: 50 * 0.6px;
      }
    }
    .ft_bindPhone_box {
      .bindPhone_item {
        width: 100%;
        height: 60 * 0.6px;
        border: 1 * 0.6px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.6px;
        margin-top: 15 * 0.6px;
        padding: 10 * 0.6px 34 * 0.6px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.6px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.7px solid #000;
        }
      }
      .bindPhone_submit {
        margin-top: 50 * 0.6px;
        height: 60 * 0.6px;
        line-height: 60 * 0.6px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.6px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.6px;
        letter-spacing: 0 * 0.6px;
      }
      .verifiy_item {
        padding-right: 200 * 0.6px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.6px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.6px;
          outline: none;
          height: 60 * 0.6px;
          line-height: 60 * 0.6px;
          font-size: 26 * 0.6px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
